import { CustomerDoc, EventDoc } from '@livekatsomo/models';
import { StoresPreview } from '@livekatsomo/web/ui-components/channels';
import {
  EventsPreview,
  EventsPreviewList,
  FeaturedEventsPreview,
} from '@livekatsomo/web/ui-components/event';
import {
  Footer,
  Header,
  HeaderProps,
  VideoBackground,
  VodBadge,
} from '@livekatsomo/web/ui-components/layout';
import { HomePageLayoutContainer } from '@livekatsomo/web/ui-components/layouts';
import { Logo } from '@livekatsomo/web/ui-components/logo';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

export interface HomeFeatureProps {
  events: EventDoc[];
  stores: CustomerDoc[] | null;
}

/**
 * Renders the home page feature of the application.
 *
 * @param events - An array of `EventDoc` objects to display on the home page.
 * @param stores - An optional array of `CustomerDoc` objects to display on the home page.
 * @param drawerMenuItems - An array of menu items to display in the header drawer.
 * @param userMenu - An optional user menu to display in the header.
 * @returns A React component that displays the home page feature.
 */
export function HomeFeature({
  events,
  stores,
  drawerMenuItems,
  userMenu,
}: HomeFeatureProps & Pick<HeaderProps, 'drawerMenuItems' | 'userMenu'>) {
  const theme = useTheme();
  const liveEvents = events.filter(
    (event) => event.startDate <= new Date() && event.endDate >= new Date(),
  );
  const upcomingEvents = events.filter((event) => event.startDate > new Date());
  const vodEvents = events.filter((event) => event.endDate < new Date());

  // Remove duplicates
  const liveAndUpcomingEvents = [
    ...new Set([...liveEvents, ...upcomingEvents]),
  ];

  // Take next three upcoming events
  const nextThreeUpcoming = upcomingEvents.slice(0, 3);

  const pinnedEvents = [...liveEvents, ...upcomingEvents, ...vodEvents].filter(
    (event) => !!event.pinToFeatured,
  );

  const liveAndNextThree = [
    ...new Set([...liveEvents, ...nextThreeUpcoming, ...pinnedEvents]),
  ];

  const featuredEvents = liveAndNextThree.length ? liveAndNextThree : vodEvents;

  const { t } = useTranslation(['common']);

  return (
    <>
      <VideoBackground
        src={'/assets/livekatsomo.fi-bg.mp4'}
        poster={'/assets/poster.jpeg'}
      />
      <Header
        title={`Rajucast`}
        drawerMenuItems={drawerMenuItems}
        userMenu={userMenu}
      />
      <HomePageLayoutContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Logo width={320} color={theme.palette.common.white} />
          <Typography
            variant="body1"
            component="h2"
            sx={{
              color: theme.palette.common.white,
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            {t('Happens to be where it happens')}
          </Typography>
        </Box>

        <>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gap: [4, 2],
              gridTemplateColumns: ['1fr', '1fr 1fr', '2fr 1fr'],
              gridAutoRows: ['500px', '400px'],
            }}
          >
            <FeaturedEventsPreview events={featuredEvents} />
            {liveAndUpcomingEvents.length ? (
              <Card
                sx={{
                  maxHeight: '100%',
                  overflow: 'auto',
                  marginY: 1,
                  minWidth: '350px',
                }}
              >
                <CardContent>
                  <EventsPreviewList
                    events={[...liveAndUpcomingEvents.values()]}
                  />
                </CardContent>
              </Card>
            ) : null}
          </Box>

          <EventsPreview title={t('Upcoming events')} events={upcomingEvents} />
          <EventsPreview
            title={
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  textShadow: '0px 0px 10px rgb(0 0 0 / 80%)',
                  alignSelf: 'flex-start',
                }}
              >
                {t('Past events available on demand')}
                <VodBadge sx={{ mx: 1 }} />
              </Typography>
            }
            events={vodEvents}
          />
          <StoresPreview title={t('Stores')} customers={stores} />
        </>
      </HomePageLayoutContainer>
      <Footer />
    </>
  );
}
